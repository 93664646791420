import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiSpacer,
  VuiText,
  VuiTitle,
} from "../../../ui";
import "./appFooter.scss";
import React from "react";

export const AppFooter = () => {
  return (
    <div className="appFooter">
      <div className="appFooterContent">
        <VuiFlexContainer
          alignItems="start"
          spacing="l"
          className="appFooterContent__layout"
        >
          <VuiFlexItem grow={5}>
            <VuiTitle size="s">
              <h3>HizbChat v1.0</h3>
            </VuiTitle>

            <VuiSpacer size="m" />

            <VuiText>
              <p>
                Partinin kitapları ile eğitilmiş soru cevap odaklı yapay zekası.
              </p>
              <p>
                İletişim: <a href="mailto:fishukee@gmail.com">fishukee@gmail.com</a>
              </p>
            </VuiText>

            <VuiSpacer size="s" />

          </VuiFlexItem>

          <VuiFlexItem grow={5}>
            <VuiTitle size="s">
              <h3>Yol Haritası</h3>
            </VuiTitle>

            <VuiSpacer size="m" />

            <VuiText>
              <ul>
                <li>Kitaplar / Kaynak türlerine göre filtreleme</li>
                <li>Dergiler & Soru Cevaplar & Beyanlar & Diğer Partisel Yayınlar</li>
                <li>Kuranı Kerim ve Meali + Tefsirler</li>
                <li>Hadis Kaynakları</li>
                <li>Tüm İslam Kaynakları</li>
              </ul>
            </VuiText>

            <VuiSpacer size="m" />

          </VuiFlexItem>
        </VuiFlexContainer>
      </div>
    </div>
  );
};
